<template>
    <div class="demo-spin-article">
        <div class="row">
            <div class="col-md-6 mb-3">
                <label class="form-label col-label required">{{
                    $t("monthlyAllowance.cycleYear")
                }}</label>
                <DatePicker
                    type="year"
                    format="yyyy"
                    placeholder="Select Year"
                    @on-change="onChangeYear"
                    :value="cycle_year"
                ></DatePicker>
            </div>
            <div class="col-md-6">
                <label class="form-label col-label required">{{
                    $t("monthlyAllowance.cycleMonth")
                }}</label>
                <div>
                    <DatePicker
                        type="month"
                        format="MM"
                        placeholder="Select Month"
                        @on-change="onChangeMonth"
                        :value="cycle_month"
                    >
                    </DatePicker>
                </div>
            </div>
            <div class="col-md-12 mb-5">
                <label class="col-label form-label required tw-text-xs">{{
                    $t("monthlyAllowance.allowance")
                }}</label>
                <div>
                    <Select
                        v-model="allowance_id"
                        :class="{
                            'is-invalid': errors.has('allowance_id')
                        }"
                    >
                        <Option
                            v-for="(payrollItemAllowance,
                            index) in payrollItemAllowances"
                            :value="payrollItemAllowance.allowance_id"
                            :key="index"
                            :label="payrollItemAllowance.allowance_item"
                            >{{ payrollItemAllowance.allowance_item }}
                        </Option>
                    </Select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('allowance_id')"
                    >
                        {{ errors.first("allowance_id") }}
                    </div>
                </div>
            </div>
            <div>
                <div class="col-md-12 mb-3">
                    <label
                        for="file"
                        class="tw-border tw-p-2 tw-rounded-md tw-cursor-pointer required"
                        >Browse File</label
                    >
                    <input
                        type="file"
                        ref="file"
                        id="file"
                        class="tw-hidden"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        @change="onFileChange"
                        :class="{
                            'is-invalid': errors.has('file')
                        }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('file')"
                    >
                        {{ errors.first("file") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    {{ display_name }}
                </div>
            </div>
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t("cancel") }}
                </ts-button>
                <ts-button
                    color="primary"
                    :waiting="waiting"
                    :disabled="waiting"
                    @click.prevent="onSave"
                >
                    {{ $t("save") }}
                </ts-button>
            </div>
        </div>
    </div>
</template>
<script>
import { Errors } from "form-backend-validation";
import moment from "moment";
import { mapActions } from "vuex";

export default {
    name: "import-monthly-allowance",
    data() {
        return {
            errors: new Errors(),
            display_name: null,
            loading: false,
            waiting: false,
            cycle_year: moment().format("YYYY"),
            cycle_month: moment().format("MM"),
            allowance_id: null
        };
    },
    computed: {
        payrollItemAllowances() {
            return this.$store.state.payroll.monthlyAllowance
                .payrollItemAllowances;
        }
    },
    methods: {
        ...mapActions("payroll/monthlyAllowance", ["getPayrollItemAllowance"]),
        fetchResource() {
            this.loading = true;
            this.getPayrollItemAllowance()
                .then(() => {})
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            let formData = new FormData();
            formData.append("file", this.file);
            formData.append("cycle_year", this.cycle_year);
            formData.append("cycle_month", this.cycle_month);
            formData.append("allowance_id", this.allowance_id);
            this.$store
                .dispatch("payroll/monthlyAllowance/importExcel", formData)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onChangeYear(date) {
            this.cycle_year = date;
        },
        onChangeMonth(date) {
            this.cycle_month = date;
        },
        onFileChange(e) {
            this.display_name = null;
            this.file = e.target.files[0];
            this.display_name = this.file ? this.file.name : null;
        },
        clearInput() {
            this.errors = new Errors();
            this.allowance_id = null;
            this.display_name = null;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "MONTHLY ALLOWANCE",
                desc: not.text
            });
        }
    }
};
</script>
